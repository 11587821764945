import React, { useState } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

import { oauth2Authorize } from "../api/oauth2-authorize";
import { settings, animations } from "../settings";
import {
  Password,
  Email,
} from "../components";

import { notifyError } from "../lib/notifications";
import { checkEmailValid } from "../helpers";

export const redirectToClient = (url) => {
  window.location = url;
}

export const LoginWithBreathe = ({ clientId, redirectUri }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const { animate } = settings;
  const { block, form } = animations;

  const usernameIsValidEmail = () => {
    return checkEmailValid(username);
  };
  const usernameIsNotValidEmail = () => {
    return !usernameIsValidEmail();
  }

  const signIn = (e) => {
    e.preventDefault();
    setLoading(true);
    const body = {
      username,
      password,
      client_id: clientId,
      redirect_uri: redirectUri,
    };

    oauth2Authorize(body)
      .then((res) => {
        redirectToClient(res.data.redirect_uri);
        return true;
      })
      .catch((error) => {
        setLoading(false);

        if (!error.response) {
          return notifyError("Network error");
        }

        if (error.response.status === 400) {
          return notifyError(error.response.data.error);
        }

        if (error.response.status === 401) {
          return notifyError("Incorrect username or password");
        }

        return notifyError("Something went wrong");
      });

  };

  return (
    <>
      <Helmet>
        <title>Sign in with Breathe</title>
      </Helmet>
      <h1 className="text-center text-black font-medium text-2xl mb-8 tracking-tight">
        Sign in with Breathe
      </h1>

      <motion.form
        variants={form}
        initial={animate ? "hidden" : ""}
        animate={animate ? "show" : ""}
      >
        <motion.div
          variants={block}
          className="flex flex-col w-full pb-4 pt-10 px-4 sm:px-16 text-sm bg-grey-light "
        >
          <Email
            username={username}
            setUsername={setUsername}
          />
        </motion.div>

        <motion.div
          variants={block}
          className="flex flex-col w-full px-4 sm:px-16 text-sm bg-grey-light"
        >
          <Password
            password={password}
            setPassword={setPassword}
          />
        </motion.div>

        <motion.div variants={block} className="centered bg-grey-light pb-6">
          <button
            type="submit"
            className={`btn mb-2 btn-green max-w-138 w-full h-36
              ${usernameIsValidEmail() ? "grows" : "disabled"}`}
            onClick={signIn}
            disabled={usernameIsNotValidEmail()}
          >
            {loading ? (
              <FontAwesomeIcon icon={faSpinner} spin aria-hidden />
            ) : (
              "Sign in"
            )}
          </button>
        </motion.div>
      </motion.form>
    </>
  );
};

LoginWithBreathe.propTypes = {
  clientId: PropTypes.string.isRequired,
  redirectUri: PropTypes.string.isRequired,
};
